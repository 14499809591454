import moment from 'moment';

import {
  EXPORT_CLOCK_IN_OUT_LIST,
  EXPORT_CLOCK_IN_OUT_LIST_FAILURE,
  EXPORT_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_ALL_MEMBERS_LATEST_CLOCK_IN_OUT,
  GET_ALL_MEMBERS_LATEST_CLOCK_IN_OUT_FAILURE,
  GET_ALL_MEMBERS_LATEST_CLOCK_IN_OUT_SUCCESS,
  GET_CLOCK_IN_OUT_LIST,
  GET_CLOCK_IN_OUT_LIST_FAILURE,
  GET_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_GROUPED_CLOCK_IN_OUT_LIST,
  GET_GROUPED_CLOCK_IN_OUT_LIST_FAILURE,
  GET_GROUPED_CLOCK_IN_OUT_LIST_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
} from '../../modules/constants';

export const clockInOutListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const clockInOutListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    // clockInOutListCount: data.data.count,
    // clockInOutPageSize: data.data.page_size,
    clockInOutList: data.data,
  };
};
export const clockInOutListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    clockInOutListCount: null,
    clockInOutPageSize: null,
    clockInOutList: [],
  };
};

export const clockInOutGroupedListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const clockInOutGroupedListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    // clockInOutListCount: data.data.count,
    // clockInOutPageSize: data.data.page_size,
    clockInOutList: data.data,
  };
};
export const clockInOutGroupedListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    clockInOutListCount: null,
    clockInOutPageSize: null,
    clockInOutList: [],
  };
};
export const clockInOutListExportRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const clockInOutListExportRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, start_timestamp, end_timestamp },
  } = action;

  if (data.status === 200) {
    try {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `attendance_report_${moment(start_timestamp * 1000).format(
          'D_MM_YYYY',
        )}_${moment(end_timestamp * 1000).format('D_MM_YYYY')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {}
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const clockInOutListExportRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...initialState,
  };
};

export const handleGetAllMemberLastClockInOutRequest = (state, action) => {
  return {
    ...state,
  };
};

export const handleGetAllMemberLastClockInOutSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    const onlineMembers = data?.data
      .filter(
        details =>
          details?.clock_in_out.clockin_timestamp > 0 &&
          !details?.clock_in_out.clockout_timestamp > 0,
      )
      ?.map(details => details?.employee.id);
    return {
      ...state,
      onlineMembers: onlineMembers,
    };
  } else {
    return {
      ...state,
      onlineMembers: [],
    };
  }
};

export const handleGetAllMemberLastClockInOutFailure = (state, action) => {
  return {
    ...state,
    onlineMembers: [],
  };
};

const ACTION_HANDLERS = {
  [GET_CLOCK_IN_OUT_LIST]: clockInOutListRequestHandler,
  [GET_CLOCK_IN_OUT_LIST_SUCCESS]: clockInOutListRequestSuccessHandler,
  [GET_CLOCK_IN_OUT_LIST_FAILURE]: clockInOutListRequestFailureHandler,
  [GET_GROUPED_CLOCK_IN_OUT_LIST]: clockInOutGroupedListRequestHandler,
  [GET_GROUPED_CLOCK_IN_OUT_LIST_SUCCESS]:
    clockInOutGroupedListRequestSuccessHandler,
  [GET_GROUPED_CLOCK_IN_OUT_LIST_FAILURE]:
    clockInOutGroupedListRequestFailureHandler,
  [EXPORT_CLOCK_IN_OUT_LIST]: clockInOutListExportRequestHandler,
  [EXPORT_CLOCK_IN_OUT_LIST_SUCCESS]: clockInOutListExportRequestSuccessHandler,
  [EXPORT_CLOCK_IN_OUT_LIST_FAILURE]: clockInOutListExportRequestFailureHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,

  [GET_ALL_MEMBERS_LATEST_CLOCK_IN_OUT]:
    handleGetAllMemberLastClockInOutRequest,
  [GET_ALL_MEMBERS_LATEST_CLOCK_IN_OUT_SUCCESS]:
    handleGetAllMemberLastClockInOutSuccess,
  [GET_ALL_MEMBERS_LATEST_CLOCK_IN_OUT_FAILURE]:
    handleGetAllMemberLastClockInOutFailure,
};

const initialState = {
  isLoading: false,
  clockInOutList: [],

  // pagination
  clockInOutListCount: null,
  clockInOutPageSize: null,
  onlineMembers: [],
};

export default function clockInOutReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
