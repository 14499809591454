import AddSingleMemberDropdown from 'components/CustomDropdown/AddSingleMemberDropdown';
import ReactDateRangePicker from 'components/ReactDateRangePicker/ReactDateRangePicker';
import BackendExportModal from 'custom_modules/ReportsNew/BackendExportModal';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import LeftArrow_3 from '../../assets/img/icons/LeftArrow_3.svg';
import RightArrow_3 from '../../assets/img/icons/RightArrow_3.svg';
import excelIcon from '../../assets/img/icons/excel-green.svg';
import ExportIcon from '../../assets/img/icons/export_icon.svg';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import Select from '../../components/ReactSelectDropdown';
import { filterOption } from '../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import DatePicker from '../../components/SingleDatePicker/SingleDatePicker';
import {
  ButtonIcon,
  ColoredButton,
  ExportButtonIcon,
  ExportButtonWithIcon,
  FilterButton,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../styledComponents/buttons';
import {
  CommonFlex,
  FilterLabel,
  HeaderContainer,
  IconArrow,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import { TabLeftGrid } from '../../styledComponents/members';
import { exportToExcel } from '../../utils/exportToExcel';
import ClockInOutTable from './ClockInOutTable';

const ClockInOut = ({
  history,
  isLoading,
  getClockInOutList,
  clockInOutList,

  getOfficesShortList,
  officesShortList,

  // pagination
  clockInOutListCount,
  clockInOutPageSize,

  selectedOrganization,
  getOrganizationMembersList,
  organizationMembersList,
  exportClockInOutList,
  getAllMemberLastClockInOut,
  onlineMembers,
}) => {
  const [startDate, setStartDate] = useState(
    moment().startOf('isoWeek').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('isoWeek').format('YYYY-MM-DD'),
  );

  const [selectedOffice, setSelectedOffice] = useState(null);
  const [officeOptions, setOfficeOptions] = useState([]);

  const [selectedMember, setSelectedMember] = useState('');
  const [memberOptions, setMemberOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');
  const [attendanceList, setAttendanceList] = useState([]);
  const [attendanceFilter, setAttendanceFilter] = useState({
    value: null,
    label: '(All Attendance)',
  });
  const attendanceFilterOptions = [
    { value: null, label: '(All Attendance)' },
    { value: 'clocked_in', label: 'Clocked In' },
    { value: 'not_clocked_in', label: 'Not Clocked In' },
  ];
  const [reportLoading, setReportLoading] = useState(false);
  const [allReportLoading, setAllReportLoading] = useState(false);

  const { t } = useTranslation();
  const [dateLabel, setDateLabel] = useState('');

  const [dateChanged, setDateChanged] = useState(false);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [timeFormat, setTimeFormat] = useState('1h5m');
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const updateAttendanceFilter = attendanceFilter => {
    setAttendanceFilter(attendanceFilter);
    setCurrentPageNumber(1);
  };
  const onMemberSelect = person => {
    setCurrentPageNumber(1);
    setSelectedMember(person);
  };
  const clearSelectedMember = () => {
    setSelectedMember(null);
  };
  const singleDateChange = direction => {
    if (direction === 'right') {
      setStartDate(moment(endDate).add(1, 'days'));
      setEndDate(moment(endDate).add(1, 'days'));
    } else {
      setStartDate(moment(startDate).subtract(1, 'days'));
      setEndDate(moment(startDate).subtract(1, 'days'));
    }
    setDateChanged(!dateChanged);
  };
  const selectToday = () => {
    setStartDate(moment().format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
    setDateChanged(!dateChanged);
  };
  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
  };
  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, 'days').format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
  };
  const toggle = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const changeDateRange = dates => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
  };
  const getDateLabel = () => {
    const diff = Math.ceil(
      moment(endDate).diff(moment(startDate), 'days', true),
    );
    if (
      diff === 0 &&
      moment().format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')
    ) {
      return 'Today selected';
    }
    return diff + 1 + ' day' + (diff > 0 ? 's' : '') + ' ' + 'selected';
  };
  useEffect(() => {
    if (startDate && endDate) {
      const maxEndDate = moment(startDate).add(3, 'months');
      if (moment(endDate).diff(moment(startDate), 'months', true) > 3) {
        setEndDate(maxEndDate);
      }
      setDateLabel(getDateLabel());
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (organizationMembersList?.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);
  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setSelectedMember('');
      setCurrentPageNumber(1);
      getOfficesShortList({ organization_id: selectedOrganization.id });
      getOrganizationMembersList({
        organization_id: selectedOrganization.id,
      });
      getAllMemberLastClockInOut({
        organization_id: selectedOrganization.id,
      });
    }
  }, [selectedOrganization]);
  // useEffect(() => {
  //   if (selectedOrganization && selectedOrganization.id) {
  //     setSelectedMember('');
  //     setCurrentPageNumber(1);
  //     getOrganizationMembersList({
  //       organization_id: selectedOrganization.id,
  //       office_id: selectedOffice?.value,
  //     });
  //   }

  //   // getData();
  // }, [selectedOffice]);

  useEffect(() => {
    if (officesShortList && officesShortList.length > 0) {
      selectOffice({
        label: officesShortList[0].name,
        value: officesShortList[0].id,
      });
      let newArr = [];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (clockInOutList && clockInOutList.length > 0) {
      const newAttendances = [];
      clockInOutList.forEach(element => {
        if (element.attendances && element.attendances.length > 1) {
          let newList = element.attendances.sort(
            (a, b) => a.timestamp - b.timestamp,
          );

          let i = 0;
          while (i < newList.length) {
            const data = {
              employee: element.employee,
              attendance: {},
            };
            let iteratorValue = 1;
            if (newList[i].type === 'clock_in') {
              data.attendance.clockIn = newList[i];
              if (newList[i + 1] && newList[i + 1].type === 'clock_out') {
                data.attendance.clockOut = newList[i + 1];
                iteratorValue = 2;
              }
            } else if (newList[i].type === 'clock_out') {
              data.attendance.clockOut = newList[i];
              data.attendance.clockIn = null;
            }

            newAttendances.push(data);
            i = i + iteratorValue;
          }
        } else if (element.attendances && element.attendances.length === 1) {
          const data = {
            employee: element.employee,
            attendance: {},
          };
          if (element.attendances[0].type === 'clock_in') {
            data.attendance.clockIn = element.attendances[0];
            data.attendance.clockOut = null;
          } else if (element.attendances[0].type === 'clock_out') {
            data.attendance.clockIn = null;
            data.attendance.clockOut = element.attendances[0];
          }
          newAttendances.push(data);
        }
      });
      setAttendanceList(newAttendances);
    }
  }, [clockInOutList]);

  // const handleSearchByName = () => {
  //   let name = '';
  //   if (searchTerm && searchTerm.trim()) {
  //     name = searchTerm.toLocaleLowerCase().trim();
  //     setCurrentSearchedTerm(searchTerm);
  //   } else {
  //     setCurrentSearchedTerm('');
  //   }
  //   getData(selectedDate, name, null, selectedOffice?.value);
  // };
  // const handleClearSearch = () => {
  //   if (currentSearchedTerm) {
  //     getData(selectedDate, null, null, selectedOffice?.value);
  //   }
  //   setTimeout(() => {
  //     setSearchTerm('');
  //     setCurrentSearchedTerm('');
  //   }, 100);
  // };

  const handlePageChange = page => {
    setCurrentPageNumber(page);
  };

  const getData = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_timestamp: moment(startDate).startOf('day').unix(),
        end_timestamp: moment(endDate).endOf('day').unix(),
        tz: selectedOrganization.timezone,
        attendanceFilter: attendanceFilter?.value,
      };
      // if (currentSearchedTerm) {
      //   payload.name = currentSearchedTerm.toLocaleLowerCase().trim();
      // }
      if (selectedMember?.user_id) {
        payload.employee_id = selectedMember.user_id;
      }
      if (currentPageNumber) {
        payload.page = currentPageNumber;
      }

      getClockInOutList(payload);
    }
  };

  const selectOffice = e => {
    setSelectedOffice(e);
  };
  useEffect(() => {
    getData();
  }, [
    dateChanged,
    currentPageNumber,
    currentSearchedTerm,
    selectedMember,
    selectedOrganization,
    attendanceFilter,
  ]);
  useEffect(() => {
    if (startDate && !endDate) {
      setEndDate(startDate);
    }
    if (startDate && endDate) {
      const maxEndDate = moment(startDate).add(3, 'months');
      if (moment(endDate).diff(moment(startDate), 'months', true) > 3) {
        setEndDate(maxEndDate);
      }
    }
  }, [startDate, endDate]);

  // const onSearchTermChange = e => {
  //   const { value } = e.target;
  //   setSearchTerm(value);
  // };

  // const handleExportToExcel = async () => {
  //   const url = `albait/export/attendances/?date=${moment(
  //     selectedDate,
  //   ).unix()}${selectedOffice?.value ? `&office=${selectedOffice.value}` : ''}`;

  //   setReportLoading(true);
  //   const response = await exportToExcel(url, null, true);
  //   setReportLoading(false);
  // };
  const handleExportClockInOutList = () => {
    const name = currentSearchedTerm.toLocaleLowerCase().trim();
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_timestamp: moment(startDate).startOf('day').unix(),
        end_timestamp: moment(endDate).endOf('day').unix(),
        time_format: timeFormat,
        tz: selectedOrganization.timezone,
        attendanceFilter: attendanceFilter?.value,
      };
      if (selectedMember?.user_id) {
        payload.employee_id = selectedMember.user_id;
      }
      // if (name) {
      //   payload.name = name;
      // }

      exportClockInOutList(payload);
    }
    toggle();
  };

  // const handleExportAllOfficesReportToExcel = useDebouncedCallback(
  //   async () => {
  //     const baseUrl = `albait/export/attendances/?date=${moment(
  //       selectedDate,
  //     ).unix()}`;

  //     if (officesShortList && officesShortList.length > 0) {
  //       setAllReportLoading(true);
  //       const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  //       const promises = [];
  //       officesShortList.forEach(async (office, i) => {
  //         const promise = (async index => {
  //           await delay(300 * index);
  //           const url = `${baseUrl}&office=${office.id}`;
  //           await exportToExcel(url, `office${office.name}.xlsx`, false);
  //         })(i);

  //         promises.push(promise);
  //       });

  //       await Promise.all(promises);

  //       setAllReportLoading(false);
  //     }
  //   },
  //   60000,
  //   { leading: true, trailing: false },
  // );

  return (
    <div className="content">
      <BackendExportModal
        isOpen={isExportModalOpen}
        toggle={toggle}
        excelExport={handleExportClockInOutList}
        title="Clock In/Out"
        timeFormat={timeFormat}
        setTimeFormat={setTimeFormat}
      />
      <PageTitle>{t('attendance')}</PageTitle>
      <HeaderContainer>
        <CommonFlex alignItems="end">
          {/* <SearchWithButton
            itemName="Member"
            searchTermName={t('member_name')}
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          /> */}
          {/* {selectedOrganization && selectedOrganization.role !== 'member' && (
            <InputWithLabelSection>
              <FilterLabel>{t('office')}</FilterLabel>

              <Select
                isSearchable
                value={selectedOffice}
                options={officeOptions}
                placeholder="Select Office..."
                onChange={e => {
                  selectOffice(e);
                }}
                styles={CreateProjectDropdown()}
                // clearable
                filterOption={filterOption}
              />
            </InputWithLabelSection>
          )} */}
          <InputWithLabelSection>
            <FilterLabel>Attendance</FilterLabel>
            <div style={{ width: `180px` }}>
              <Select
                isSearchable={false}
                value={attendanceFilter}
                options={attendanceFilterOptions}
                placeholder=""
                onChange={e => {
                  updateAttendanceFilter(e);
                }}
                styles={CreateProjectDropdown(null)}
              />
            </div>
          </InputWithLabelSection>
          <InputWithLabelSection>
            <FilterLabel>Member</FilterLabel>
            <div style={{ width: `40px` }}>
              <AddSingleMemberDropdown
                assigneeManage={selectedMember}
                updateState={onMemberSelect}
                membersList={memberOptions}
                clearAssignee={() => clearSelectedMember()}
              />
            </div>
          </InputWithLabelSection>{' '}
          <CommonFlex gap="4px" alignItems="end">
            <InputWithLabelSection>
              <FilterLabel>
                {t('date_range')}
                {` (${dateLabel})`}
              </FilterLabel>
              <ReactDateRangePicker
                startDate={startDate ? moment(startDate) : null}
                startDateId="report-date-range-start-date"
                endDate={endDate ? moment(endDate) : null}
                endDateId="report-date-range-end-date"
                onDatesChange={changeDateRange}
                dateDisplayFormat="DD/MM/YYYY"
                onDateSelect={() => {
                  setDateChanged(!dateChanged);
                }}
                selectLastWeek={selectLastWeek}
                selectLastMonth={selectLastMonth}
                rangeRestricted={true}
                disableFutureDates={true}
              />
            </InputWithLabelSection>
            <IconArrow
              onClick={() => {
                singleDateChange('left');
              }}
              padding="5px"
              size="39px"
              src={LeftArrow_3}
            />
            <IconArrow
              onClick={() => {
                moment(endDate).isBefore(moment().subtract(1, 'day')) &&
                  singleDateChange('right');
              }}
              disabled={!moment(endDate).isBefore(moment().subtract(1, 'day'))}
              padding="5px"
              size="39px"
              src={RightArrow_3}
            />
            <WhiteButton
              type="nav"
              style={{
                height: '38px',
                padding: '0px 15px',
              }}
              onClick={selectToday}
            >
              Today
            </WhiteButton>
          </CommonFlex>
        </CommonFlex>

        <CommonFlex alignItems="end">
          {(selectedOrganization?.role === 'admin' ||
            selectedOrganization?.role === 'owner' ||
            selectedOrganization?.role === 'team_leader') &&
            clockInOutList?.length >= 0 && (
              <ExportButtonWithIcon onClick={() => toggle()}>
                <ExportButtonIcon src={ExportIcon} alt="" size="15px" />
                Export
              </ExportButtonWithIcon>
            )}
        </CommonFlex>
        {/* <CommonFlex gap="12px">
          {localStorage.getItem('user_role') === 'admin' &&
          !localStorage.getItem('viewer_role') ? (
            <ColoredButton
              type="blue"
              onClick={() => handleExportAllOfficesReportToExcel()}
              padding={allReportLoading ? '0 45px' : '0 25px'}
            >
              {allReportLoading ? (
                <ButtonTextLoader color="#fff" loadingText=" " size={6} />
              ) : (
                <>Generate All Report</>
              )}
            </ColoredButton>
          ) : null}
          {localStorage.getItem('user_role') !== 'employee' ||
          localStorage.getItem('user_role') !== 'supervisor' ? (
            <WhiteButtonWithIcon
              onClick={() => handleExportToExcel()}
              color="#19AC65"
              borderColor="#19AC65"
              hoverBorderColor="#129652"
              padding={reportLoading ? '0 45px' : '0 25px'}
            >
              {reportLoading ? (
                <ButtonTextLoader color="#19AC65" loadingText=" " size={6} />
              ) : (
                <>
                  <ButtonIcon src={excelIcon} size="26px" />
                  {t('export_report')}
                </>
              )}
            </WhiteButtonWithIcon>
          ) : null}
        </CommonFlex> */}
      </HeaderContainer>
      {clockInOutListCount && clockInOutListCount > 0 && clockInOutPageSize ? (
        <TopPagination
          itemName="members"
          totalCount={clockInOutListCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={clockInOutPageSize}
        />
      ) : null}
      <ClockInOutTable
        clockInOutList={clockInOutList?.map(data => ({
          ...data,
          isOnline: onlineMembers.includes(data.employee.id),
        }))}
        isLoading={isLoading}
        history={history}
        startDate={startDate}
        endDate={endDate}
      />
      {clockInOutListCount && clockInOutListCount > 0 && clockInOutPageSize ? (
        <BottomPagination
          totalCount={clockInOutListCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={clockInOutPageSize}
        />
      ) : null}
    </div>
  );
};

export default ClockInOut;
