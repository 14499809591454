import {
  EXPORT_CLOCK_IN_OUT_LIST,
  GET_ALL_MEMBERS_LATEST_CLOCK_IN_OUT,
  GET_CLOCK_IN_OUT_LIST,
  GET_GROUPED_CLOCK_IN_OUT_LIST,
} from '../../modules/constants';

export function getClockInOutList(payload) {
  return {
    type: GET_CLOCK_IN_OUT_LIST,
    payload,
  };
}
export function getGroupedClockInOutList(payload) {
  return {
    type: GET_GROUPED_CLOCK_IN_OUT_LIST,
    payload,
  };
}
export function exportClockInOutList(payload) {
  return {
    type: EXPORT_CLOCK_IN_OUT_LIST,
    payload,
  };
}

export function getAllMemberLastClockInOut(payload) {
  return {
    type: GET_ALL_MEMBERS_LATEST_CLOCK_IN_OUT,
    payload,
  };
}
