import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import Plus from '../../assets/img/icons/plus_white.svg';
import refresh_white from '../../assets/img/icons/refresh_white.svg';
import ProjectDropdown from '../../components/CommonFilter/ProjectDropdown';
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import Select from '../../components/ReactSelectDropdown';
import { filterOption } from '../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import DatePicker from '../../components/SingleDatePicker/SingleDatePicker';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
} from '../../styledComponents/buttons';
import {
  CommonFlex,
  CommonGrid,
  FilterLabel,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import { TabLeftGrid } from '../../styledComponents/offices';
import { FilterContainer } from '../../styledComponents/tasks';

const MembersFilter = props => {
  const { t } = useTranslation();

  const filterOptions = [
    {
      value: 'all',      
      label: '(All members)',
    },
    {
      value: 'office_assigned',
      label: t('office_assigned'),
    },
    {
      value: 'office_not_assigned',
      label: t('office_not_assigned'),
    }
  ]

  const {
    selectedOrganization,
    getOfficesShortList,
    officesShortList,
    selectedOffice,
    selectOffice,
    handleSearchByName,
    handleClearSearch,
    searchTerm,
    onSearchTermChange,
    history,
    ghlCustomerFetch,
    userCodeSearchTerm,
    onUserCodeSearchTermChange,
    handleSearchByUserCode,
    handleClearUserCodeSearch,
    selectedRole,
    onRoleChange,
    activeTab,
    filterValue,
    handleFilterChange,
  } = props;

  return (
    <div className="content">
      <HeaderContainer 
        margin="0px"
      >
        {activeTab != 'invited' ? (
          <TabLeftGrid>
            <CommonFlex>
              <div style={{ width: "250px" }}>
                <SearchWithButton
                  itemName={t('member')}
                  searchTermName={`${t('member_info')} `}
                  searchInput={searchTerm}
                  onInputChange={onSearchTermChange}
                  handleSearch={handleSearchByName}
                  handleClearSearch={handleClearSearch}
                  fontSize="12.5px"
                />
              </div>

              <InputWithLabelSection style={{ width: "190px"}}>
                <FilterLabel>{t('filter')}</FilterLabel>
                <Select
                  isSearchable={false}
                  value={filterValue}
                  options={filterOptions}
                  placeholder="Select Filter..."
                  onChange={e => {
                    e
                      ? handleFilterChange(e)
                      : handleFilterChange({
                          label: '(All members)',
                          value: 'all',
                        });
                  }}
                  styles={FilterDropDownStyle({
                    height: '40px',
                    width: '150px',
                    menuWidth: '130px',
                    height: '40px',
                    width: '150px',
                    menuWidth: '130px',
                  })}
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: props => {
                      return <ClearFilterButton {...props} />;
                    },
                  }}
                />
              </InputWithLabelSection>
            </CommonFlex>
          </TabLeftGrid>
        ) : (
          <div style={{
            height: '64px',
          }}></div>
        )}
        {/* {localStorage.getItem('user_role') &&
          localStorage.getItem('user_role') === 'admin' &&
          !localStorage.getItem('viewer_role') && (
            <ColoredButtonWithIcon
              // onClick={() => history.push('/user/member-add-update')}
              onClick={() => history.push('/user/member-invitation')}
            >
              <ButtonIcon src={Plus} />
              {t('invite_member')}
            </ColoredButtonWithIcon>
          )} */}
        <CommonGrid>
          {selectedOrganization &&
          (selectedOrganization.role === 'owner' ||
            selectedOrganization.role === 'admin') &&
          selectedOrganization.ghl_id ? (
            <ColoredButtonWithIcon
              style={{ background: '#606CFF' }}
              onClick={() => ghlCustomerFetch()}
            >
              <ButtonIcon size="20px" src={refresh_white} alt="" />
              Sync Member
            </ColoredButtonWithIcon>
          ) : null}
          {selectedOrganization &&
          (selectedOrganization.role === 'owner' ||
            selectedOrganization.role === 'admin') ? (
            <ColoredButtonWithIcon
              onClick={() => history.push('/user/member-invitation')}
            >
              <ButtonIcon src={Plus} alt="" />
              Invite Member
            </ColoredButtonWithIcon>
          ) : null}
        </CommonGrid>
      </HeaderContainer>
    </div>
  );
};

export default MembersFilter;
