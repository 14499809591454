import ReactSlider from 'react-slider';
import styled, { css } from 'styled-components';

import { TableText } from './common';

// ----- COMMON -----
export const AdditionalContainer = styled.div`
  padding: 10px 0;
`;
export const AdditionalOptionSection = styled.div`
  border-top: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.border.primary : theme.colors.border.secondary};
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
`;
export const AdditionalOptionText = styled.p`
  margin: 0 30px;
  position: relative;
  top: -11px;
  background-color: ${({ theme }) => theme.colors.main.white};
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.main.labelText};
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: auto 30px;
  align-content: center;
  justify-content: space-between;
  padding: ${({ padding }) => padding || '10px 30px'};
  cursor: pointer;
  @media (max-width: 600px) {
    padding: 10px 15px;
  }
`;
export const IconTitleSection = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 10px;
  align-content: center;
  justify-content: start;
  @media (max-width: 600px) {
    grid-column-gap: 10px;
  }
`;
export const CollapseContainer = styled.div`
  display: grid;
  max-height: ${({ active, maxHeight }) =>
    active ? (maxHeight ? maxHeight : `1200px`) : `0`};
  overflow: ${({ addAssignee, overflow }) =>
    addAssignee ? `visible` : overflow ? overflow : `hidden`};
  transition: max-height 0.8s ease;
  padding: 0 30px;
  @media (max-width: 450px) {
    padding: 0 10px;
  }
`;
export const GeneralDetailsContainer = styled.div`
  display: grid;
  max-height: ${props => (props.active ? `400px` : `0px`)};
  overflow: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: max-height 0.5s ease;
  @media (max-width: 450px) {
    padding: 0 10px;
  }
`;
export const CollapseRotate = styled.div`
  transform: ${props => (props.active ? `rotate(0deg)` : `rotate(-90deg)`)};
  transition: transform 0.8s ease;
`;

export const InputField = styled.input`
  border-radius: ${({ theme }) => theme.input.borderRadius};
  border: 1px solid
    ${({ borderColor, theme }) =>
      borderColor || theme.input.borderColor.default};
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  padding: 5px 10px;
  width: 100%;
  min-height: ${({ height, theme }) => height || theme.input.minHeight};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:hover {
    border-color: ${({ hoverBorderColor, theme }) =>
      hoverBorderColor || theme.input.borderColor.hover};
    box-shadow: ${({ theme }) => theme.input.focusShadow};
  }
  &:hover {
    border-color: #88e0d7;
    box-shadow: 0 0 0 4px #eaf9f7;
  }
  &:focus {
    outline: none;
    border-color: ${({ focusBorderColor, theme }) =>
      focusBorderColor || theme.input.borderColor.focus};
    box-shadow: ${({ theme }) => theme.input.focusShadow};
  }
`;
export const ButtonSection = styled.div`
  display: grid;
  grid-template-columns: 13% 13%;
  grid-column-gap: 20px;
  max-width: 1200px;
  padding: ${props => (props.padding ? props.padding : `10px 0px`)};
  @media (max-width: 1200px) {
    grid-template-columns: 140px 140px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 400px) {
    grid-template-columns: 180px;
    grid-gap: 20px;
    justify-content: center;
  }
`;
export const AssigneesManageImgTextGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-content: center;
  grid-column-gap: ${props => (props.columnGap ? props.columnGap : `15px`)};
  @media (max-width: 450px) {
    grid-column-gap: 8px;
  }

  &:hover {
    ${TableText} {
      text-decoration: ${({ hoverUnderline }) =>
        hoverUnderline ? 'underline' : 'none'};
    }
  }
`;
export const SetRateContainer = styled(AssigneesManageImgTextGrid)`
  grid-template-columns: 100px 85px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr auto;
  }
`;

export const AssigneesManageRoundImage = styled.div`
  width: ${({ imageSize }) => imageSize || `40px`};
  height: ${({ imageSize }) => imageSize || `40px`};
  border-radius: 50%;
  position: relative;
`;
export const BillingTextTooltip = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-content: center;
  align-items: center;
  grid-column-gap: 10px;
  padding: ${props => props.padding && props.padding};
  margin-top: ${props => props.marginTop && props.marginTop};
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const BillingQues = styled.div`
  position: relative;
  border-radius: 50%;
  color: #9ea6b2;
  background-color: #e4e7eb;
  font-size: 11px;
  height: ${({ size }) => size || '19px'};
  width: ${({ size }) => size || '19px'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const BillingTooltip = styled.div`
  position: absolute;
  left: ${({ left }) => left || `-115px`};
  top: ${({ top }) => top || `-60px`};
  padding: ${({ padding }) => padding || '7px 15px'};
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor || '#1f2939'};
  width: ${({ width }) => width || '250px'};
  z-index: 5;
`;
export const BillingTooltipText = styled.div`
  font-size: 12px;
  color: #fff;
`;
export const AssigneeRoundImageSection = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'row-reverse'};
  position: relative;
  ${({ visibleMembers }) =>
    visibleMembers
      ? css`
          left: ${({ position, visibleMembers }) =>
            position < visibleMembers + 1
              ? `-${position * 15}px`
              : `-${visibleMembers * 15}px`};
        `
      : css`
          left: ${({ position }) =>
            position < 5 ? `-${position * 15}px` : `-60px`};
        `}
`;

export const DetailsAssigneeRoundImageSection = styled(
  AssigneeRoundImageSection,
)`
  left: ${({ position }) => (position < 11 ? `-${position * 15}px` : `-140px`)};
`;
export const RoundImageCross = styled.div`
  position: absolute;
  z-index: 1;
  top: -5px;
  right: -5px;
  width: ${({ size }) => size || '19px'};
  height: ${({ size }) => size || '19px'};
  border-radius: 50%;
  display: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: #979797;
  border: 2px solid ${({ theme }) => theme.colors.main.white};
  padding: 4px;
  &:hover {
    background: ${({ theme }) => theme.colors.main.error};
  }
`;
export const RoundImageTooltip = styled.div`
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ isAssignee }) => isAssignee && `column`};
  left: ${({ left }) => left || `-45px`};
  top: ${({ top }) => top || `-36px`};
  padding: 7px 7px;
  border-radius: 8px;
  font-size: 12px;
  color: ${({ textColor }) => textColor || `#fff`};
  border: ${({ border }) => border || `1px solid #1f2939`};
  background-color: ${({ bgColor }) => bgColor || `#1f2939`};
  min-width: ${({ customWidth }) => customWidth || `130px`};
  height: ${({ customHeight }) => customHeight || `30px`};
  z-index: 5;
  &::before,
  ::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${({ bgColor }) => bgColor || `#1f2939`};
    border-width: 5px;
    margin-left: -5px;
  }
  &::before {
    border-color: rgba(194, 204, 225, 0);
    border-top-color: ${({ bgColor }) => bgColor || `#1f2939`};
    border-width: 6px;
    margin-left: -6px;
  }
`;
export const AssigneeRoundImage = styled.div`
  position: relative;
  right: ${({ index }) => (index === 'more' ? `-15px` : `-${index * 15}px`)};
  width: ${({ size }) => size || '40px'};
  height: ${({ size }) => size || '40px'};
  border-radius: 50%;
  background-color: ${({ index, theme, bgColor }) =>
    bgColor
      ? bgColor
      : index === 'more'
      ? theme.colors.border.secondary
      : theme.colors.main.white};
  line-height: ${({ size }) => size || '40px'};
  text-align: center;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 10px;
  cursor: pointer;
  &:hover ${RoundImageCross} {
    display: grid;
  }
  &:hover ${RoundImageTooltip} {
    display: flex;
  }
`;
export const RoundPersonImage = styled.img`
  width: ${({ size }) => size || `40px`};
  height: ${({ size }) => size || `40px`};
  border-radius: 50%;
  border: 1px solid
    ${({ borderColor, theme }) =>
      borderColor ? borderColor : theme.colors.main.white};
`;

export const ShowAssigneeTitle = styled.div`
  border-top: 1px solid rgba(194, 204, 225, 0.5);
  display: flex;
  justify-content: flex-start;
  margin: 15px 0 -11px 12px;
`;
export const ShowAssigneeTitleText = styled.p`
  position: relative;
  top: -11px;
  padding-right: 10px;
  background-color: ${({ theme }) => theme.colors.main.white};
  color: ${({ theme }) => theme.colors.main.labelText};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin: 0;
`;

export const MainText = styled.div`
  position: relative;
  cursor: pointer;
  margin: auto 0;
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-size: ${({ theme }) => theme.font.pageText.size};
`;

// ---- CREATE PROJECT STYLE ----

export const FormAlert = styled.span`
  font-size: ${({ fontSize }) => fontSize || '13px'};
  color: ${({ theme }) => theme.colors.main.error};
  margin-top: ${({ margin }) => margin || '10px 0 0 0'};
`;

export const CreateProjectSubTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 20px;
  justify-content: start;
  align-content: center;
  margin: 3.5rem 0 1rem 0;
`;
export const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c2cce1;
  padding: ${({ padding }) => padding || `30px 0`};
  margin-top: ${({ marginTop }) => marginTop || `2.7rem`};
`;

// ---- GENERAL DETAILS STYLE ----
export const GeneralDetailsTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const GeneralDetailsInputContainer = styled.div`
  display: grid;
  grid-template-columns: 28% 28% 28%;
  grid-gap: 15px 3vw; // may be 15px
  justify-content: start;
  align-content: start;
  padding: 10px 30px;
  max-width: 1200px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 767px) {
    grid-template-columns: 260px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;
export const DescriptionDiv = styled.div`
  display: grid;
  grid-template-columns: calc(84% + 6vw);
  max-width: 1200px;
  padding: 5px 30px 10px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 767px) {
    grid-template-columns: 260px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;
export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
`;

//----- ADD PEOPLE & PERMISSIONS -----

export const ClientSection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 40px;
  align-content: center;
  justify-content: start;
  margin-top: 30px;
  @media (max-width: 374px) {
    display: flex;
    flex-flow: row wrap;
  }
`;
export const ReportShow = styled.div`
  display: grid;
  grid-template-columns: 25px auto;
  grid-column-gap: 15px;
  align-content: center;
  justify-content: start;
  cursor: pointer;
  @media (max-width: 374px) {
    margin-top: 10px;
  }
`;
export const ReportShowBox = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #e4e7eb;
  height: 25px;
`;
export const AssigneeSection = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 25px;
  align-content: center;
  justify-content: start;
  @media (max-width: 350px) {
    grid-column-gap: 15px;
  }
`;
export const AssigneesManageTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 37% 20% 20%;
  justify-content: start;
  align-content: center;
  grid-gap: 25px;
  max-width: 1200px;
  margin-top: 30px;
  @media (max-width: 450px) {
    grid-template-columns: 33% 20% 20%;
    grid-column-gap: 15px;
  }
  @media (max-width: 330px) {
    grid-column-gap: 10px;
  }
`;
export const AssigneesManageInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 37% 20% 20% 5%;
  justify-content: start;
  grid-gap: 25px;
  max-width: 1200px;
  padding: 15px 0;
  max-height: 300px;
  overflow-y: auto;
  @media (max-width: 450px) {
    grid-template-columns: 33% 20% 20% auto;
    grid-gap: 25px 15px;
  }
  @media (max-width: 330px) {
    grid-gap: 25px 10px;
  }
`;
export const RadioButton = styled.div`
  width: 22px;
  height: 22px;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  padding: 2px;
  cursor: pointer;
`;
export const RadioButtonActive = styled.div`
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.main.primary : theme.colors.main.white};
  width: 100%;
  height: 100%;
`;

export const Cross = styled.div`
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  font-size: 17px;
  color: ${({ theme }) => theme.colors.main.labelText};
  margin: auto;
  cursor: pointer;
  width: ${({ size }) => size || '30px'};
  height: ${({ size }) => size || '30px'};
  display: grid;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${({ theme }) => theme.colors.main.error};
    border-color: ${({ theme }) => theme.colors.main.error};
    img {
      filter: brightness(0) saturate(100%) invert(41%) sepia(43%)
        saturate(2457%) hue-rotate(327deg) brightness(115%) contrast(99%);
    }
  }
`;
export const CrossIcon = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

// ----- ADD BILLING & HOURS -----

export const ProjectButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  grid-column-gap: 10px;
  border: 1px solid #e4e7eb;
  border-radius: 6px;
  padding: 4px;
  margin: 30px auto 20px 0;
`;
export const ProjectButton = styled.div`
  font-size: 13px;
  padding: 7px 15px;
  border-radius: 4px;
  color: ${props =>
    props.index == props.projectTypeSelect ? `white` : `#2f394e`};
  background-color: ${props =>
    props.index == props.projectTypeSelect ? `#20BEAD` : `white`};
  cursor: pointer;
  white-space: nowrap;
  border: ${props =>
    props.index == props.projectTypeSelect
      ? `1px solid #20bead`
      : `1px solid white`};
  &:hover {
    border: 1px solid #20bead;
  }
`;
export const ProjectBillingSection = styled.div`
  width: 100%;
  //max-width: 900px;
  border-radius: ${({ theme }) => theme.borderRadii.card};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  margin: 20px 0;
  padding: 15px 0;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const ProjectBillingTabContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  grid-column-gap: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  padding: 0 25px;
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`;
export const ProjectBillingtab = styled.div`
  position: relative;
  top: 1px;
  font-size: 14px;
  padding: 0 0 8px;
  color: ${({ theme }) => theme.colors.main.textMain};
  cursor: pointer;
  white-space: nowrap;
  border-bottom: ${({ workOrderBillingType, index, theme }) =>
    index === workOrderBillingType &&
    `2px solid ${theme.colors.border.primary}`};
  &:hover {
    border-bottom: ${({ workOrderBillingType, index, theme }) =>
      index !== workOrderBillingType &&
      `2px solid ${theme.colors.border.secondary}`};
  }
`;
export const HourlyRateDropdownSection = styled.div`
  display: grid;
  grid-template-columns: 220px auto;
  justify-content: start;
  align-content: center;
  grid-column-gap: 30px;
  padding: 0 25px;
  @media (max-width: 600px) {
    grid-template-columns: 220px;
    padding: 0 10px;
    grid-row-gap: 15px;
  }
`;
export const ProjectHourlyRateInputSection = styled.div`
  display: grid;
  grid-template-columns: auto 120px;
  justify-content: start;
  align-content: center;
  grid-column-gap: 10px;
`;
export const PersonHourlyRateContainer = styled.div`
  display: grid;
  grid-template-columns: 35% 1fr;
  justify-content: start;
  align-content: start;
  grid-gap: 10px 30px;
  padding: 15px 25px;
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
  @media (max-width: 600px) {
    grid-template-columns: 35% 180px;
    padding: 15px 10px;
    grid-gap: 10px 15px;
    justify-content: space-between;
  }
  @media (max-width: 375px) {
    grid-template-columns: 35% 1fr;
  }
`;
export const SetRateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  border: 1px solid
    ${({ active, theme }) =>
      active
        ? theme.colors.border.primary
        : theme.colors.border.secondaryLight};
  font-size: 13px;
  color: ${({ active, theme }) =>
    active ? theme.colors.main.white : theme.colors.main.textMain};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main.primary : theme.colors.main.white};
  min-width: 100px;
  height: 32px;
  font-weight: ${({ active, theme }) => active && theme.fontWeights.semibold};
  &:hover {
    border-color: ${({ active, theme }) =>
      active ? theme.colors.hover.primary : theme.colors.border.secondary};
    background-color: ${({ active, theme }) =>
      active && theme.colors.hover.primary};
  }
  &:focus {
    outline: none;
  }
`;
export const ProjectBudgetUpperGrid = styled.div`
  display: grid;
  grid-template-columns: 35% 1fr;
  justify-content: start;
  align-content: center;
  grid-gap: 20px 30px;
  //padding: 0 25px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 15px 30px;
    padding: 0 15px;
  }
`;
export const ProjectBudgetUpperColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  @media (max-width: 600px) {
    grid-row-gap: 15px;
  }
`;
export const DropDownGrid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.columnWidth ? props.columnWidth : '1fr'};
  justify-content: start;
  max-width: 200px;
`;
export const SliderContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 30px auto;
  justify-content: start;
  align-content: center;
  grid-column-gap: 15px;
  margin-top: 6px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr 30px auto;
    grid-column-gap: 8px;
  }
`;

// REACT SLIDER STYLE
export const StyledSlider = styled(ReactSlider)`
  margin: auto 0;
  width: 100%;
  height: 4px;
`;
export const StyledThumb = styled.div`
  top: -8px;
  height: 18px;
  line-height: 18px;
  width: 18px;
  text-align: center;
  background-color: #20bead;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  z-index: 0 !important;
  &:focus {
    outline: none;
  }
`;
export const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${props =>
    props.index === 2 ? '#f00' : props.index === 1 ? '#E4E7EB' : '#20BEAD'};
  border-radius: 999px;
`;

//ADD TASKS

export const AddtaskContainer = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.hasTask ? '35% 15% 25% 5%' : 'auto'};
  grid-gap: 10px 30px;
  justify-content: start;
  max-width: 1200px;
  padding: 30px;
  max-height: 300px;
  overflow: ${({ overflow }) => overflow || 'visible'};
  @media (max-width: 1024px) {
    grid-template-columns: ${props =>
      props.hasTask ? `auto auto auto auto` : `auto`};
    grid-column-gap: 15px;
    justify-content: space-between;
  }
`;
export const StatusButton = styled.button`
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid #e4e7eb;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: ${props =>
    props.color === 0 ? `#7F9DBF` : props.color === 1 ? `#4E85F3` : `#22BA62`};
  &:focus {
    outline: none;
  }
`;
export const AddTaskComponent = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  align-items: center;
  grid-column-gap: 10px;
  margin-top: 30px;
`;
export const AddTaskLink = styled.p`
  // margin: 20px auto 0 0;
  padding: 10px 30px 0px;
  color: ${({ theme }) => theme.colors.main.primary};
  font-size: ${({ theme }) => theme.font.pageText.size};
  cursor: pointer;
  margin: ${({ margin }) => margin || '0'};
  &:hover {
    color: ${({ theme }) => theme.colors.hover.primary};
  }
`;

export const SaveAndProceedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 38px 0px 30px;
`;
export const SaveAndProceedButton = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  background: #20bead;
  color: #fff;
  font-size: 14px;
  border-radius: 6px;
`;

export const DetailsAssigneeSectionContainer = styled.div`
  margin: 30px 0px 15px;
`;
