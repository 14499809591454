import styled from 'styled-components';

export const ClockInOutTableHeader = styled.div`
  display: grid;
  grid-template-columns:
    minmax(250px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr)
    minmax(120px, 1fr)
    minmax(120px, 1fr) minmax(200px, 2fr) 0.5fr;
  grid-column-gap: 20px;
  justify-content: start;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1400px) {
    grid-template-columns:
      minmax(190px, 2fr) 100px 130px 80px minmax(120px, 1.5fr)
      50px;
    grid-column-gap: 10px;
  }
`;

export const ClockInOutTableRow = styled.div`
  display: grid;
  grid-template-columns:
    minmax(250px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr)
    minmax(120px, 1fr)
    minmax(120px, 1fr) minmax(200px, 2fr) 0.5fr;
  grid-column-gap: 20px;
  justify-content: start;
  padding: 0 20px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1400px) {
    grid-template-columns:
      minmax(190px, 2fr) 100px 130px 80px minmax(120px, 1.5fr)
      50px;
    grid-column-gap: 10px;
  }
`;
export const ClockInOutTableHeaderNew = styled.div`
  display: grid;
  grid-template-columns: 200px 300px;
  grid-column-gap: 20px;
  justify-content: start;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  /* @media (max-width: 1250px) {
    grid-template-columns: 250px 200px 200px 150px;
    grid-column-gap: 10px;
  } */
`;
